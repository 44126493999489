<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div class="content">
            <div class="card">
                <div class="card-header bg-white">
                    <h6 class="card-title font-weight-semibold">Tambah Catatan Asuhan</h6>
                </div>
                <validation-observer ref="VForm">
                <b-form @submit.prevent="doSubmit">
                    <div class="table-responsive">
                    <table class="table table-bordered table-sm table-align-top table-striped table-hover">
                        <thead>
                        <tr>
                            <td colspan="6" class="bg-info">
                            <span class="font-weight-semibold">Catatan Asuhan Perawat</span>
                            </td>
                        </tr>
                        <tr>
                            <th width="40%">Asesmen/Tindakan</th>
                            <th width="40%">Evaluasi</th>
                            <th>PPA</th>
                            <th>Action</th>
                            <th>Hapus</th>
                        </tr>
                        </thead>
                        <tbody>
                        
                        <tr v-for="(v,k) in data" :key="k">
                            <td>
                            <div class="form-row">
                                <div class="col-12" v-if="data[k].aucap_created_from == 'CA'">
                                    <div class="mb-2 mt-1">
                                        <b-form-radio-group
                                            :options="Config.mr.ugdTindakanLanjut"
                                            v-model="data[k]['aucap_type']"
                                        />

                                        <VValidate :name="'Tindak lanjut #'+(k+1)" v-model="data[k]['aucap_type']"
                                        :rules="mrValidation.aucap_type" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <label>Tanggal</label>
                                        <div class="input-group">
                                            <datepicker input-class="form-control transparent"
                                            placeholder="Pilih Tanggal" class="my-datepicker"
                                            calendar-class="my-datepicker_calendar" v-model="data[k]['aucap_date']">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                        <VValidate :name="'Tanggal #'+(k+1)" v-model="data[k]['aucap_date']"
                                        :rules="mrValidation.aucap_date" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <label>Jam</label>
                                        <vue-timepicker manual-input v-model="data[k]['aucap_jam']" format="HH:mm" input-class="form-control"></vue-timepicker>
                                        <VValidate :name="'Jam #'+(k+1)" v-model="data[k]['aucap_jam']"
                                        :rules="mrValidation.aucap_jam" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="my-2" v-if="data[k]['aucap_type'] == 'L' && data[k].aucap_created_from == 'CA'">
                                <label for="">Asesmen/Tindakan</label>
                                <v-select 
                                    @input="changeAsesmenTindakan(k)"
                                    :options="Config.mr.ugdAssesmenTindakan"
                                    v-model="data[k]['aucap_assesmen_tindakan']"
                                    placeholder="Pilih Asesmen/Tindakan"
                                    label="text"
                                    :clearable="true" :reduce="v=>v.value"
                                >
                                </v-select>
                                <VValidate :name="'Asesmen/Tindakan #'+(k+1)" v-model="data[k]['aucap_assesmen_tindakan']"
                                    :rules="mrValidation.aucap_assesmen_tindakan" />
                            </div>

                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Melaksanakan pemberian injeksi'">
                                <div class="form-inline">
                                <label>Injeksi pada</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm mx-1">
                                </div>
                            </div>

                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan incici pada...'">
                                <div class="form-inline">
                                <label>Incici pada</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm mx-1">
                                </div>
                            </div>

                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang identitas warna...'">
                                <div class="form-inline">
                                <label>Warna gelang</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm mx-1">
                                </div>
                            </div>
                                                        
                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat... dengan nebulizer'">
                                <div class="mb-2">
                                <label>Nama Obat</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm">
                                </div>
                            </div>
                            
                            <div class="assessContent" v-if="data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat melalui titrasi syring pump dengan dosis'">
                                <div class="mb-2">
                                <label>Nama Obat</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text']" class="form-control form-control-sm">
                                </div>
                                
                                <div class="mb-2">
                                <label>Dosis</label>
                                <input v-model="data[k]['aucap_assesmen_tindakan_sup_text2']" class="form-control form-control-sm">
                                </div>
                            </div>

                            <div class="my-2" v-if="data[k]['aucap_type'] !== 'L' || (data[k]['aucap_assesmen_tindakan'] && data[k]['aucap_created_from'] != 'CA') || data[k]['aucap_assesmen_tindakan'] == 'Free Text'">
                                <label for="">Asesmen/Tindakan</label>
                                <b-form-textarea v-model="data[k]['aucap_assesmen_tindakan_text']" name="" id="" rows="3" class="form-control"></b-form-textarea>
                                <VValidate :name="'Asesmen/Tindakan Lainnya #'+(k+1)" v-model="data[k]['aucap_assesmen_tindakan_text']"
                                    :rules="mrValidation.aucap_assesmen_tindakan_text" />
                            </div>

                            <div class="assessContent"></div>
                            </td>
                            <td>
                            <div class="form-row">
                                <div class="col-md-6">
                                    <div>
                                        <label>Tanggal</label>
                                        <div class="input-group">
                                            <datepicker input-class="form-control transparent"
                                            placeholder="Pilih Tanggal" class="my-datepicker"
                                            @input="inputDataCA(data[k]['aucap_evaluasi_date'],data[k]['aucap_evaluasi_jam'],k)"
                                            calendar-class="my-datepicker_calendar" v-model="data[k]['aucap_evaluasi_date']">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i
                                                class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                        <VValidate :name="'Tanggal Evaluasi #'+(k+1)" v-model="data[k]['aucap_evaluasi_date']"
                                        :rules="mrValidation.aucap_evaluasi_date" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div>
                                        <label>Jam</label>
                                        <vue-timepicker manual-input @input="inputDataCA(data[k]['aucap_evaluasi_date'],data[k]['aucap_evaluasi_jam'],k)"
                                        v-model="data[k]['aucap_evaluasi_jam']" format="HH:mm" input-class="form-control"></vue-timepicker>
                                        <VValidate :name="'Jam Evaluasi #'+(k+1)" v-model="data[k]['aucap_evaluasi_jam']"
                                        :rules="mrValidation.aucap_evaluasi_jam" />
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mt-2" v-if="data[k]['aucap_type'] !== 'L' || data[k]['aucap_assesmen_tindakan'] == 'Free Text'">
                                <label for="">Evaluasi</label>
                                <div class="mb-2 evalContent">
                                <b-form-textarea v-model="data[k]['aucap_evaluasi_text']" id="" rows="2" class="form-control"></b-form-textarea>
                                <VValidate :name="'Evaluasi Lainnya #'+(k+1)" v-model="data[k]['aucap_evaluasi_text']"
                                :rules="mrValidation.aucap_evaluasi_text" />
                                </div>
                            </div>

                            <template v-else>
                                <div class="mt-2" v-if="data[k]['aucap_assesmen_tindakan'] == 'Memberikan oksigen'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Oksigen terpasang</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>L/menit, SpO2</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan Perekaman EKG'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Hasil Terlampir</span>
                                    </div>
                                </div>
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang bedside monitor'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Monitor terpasang</span>
                                    </div>
                                </div>
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemeriksaan cek gula darah acak'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Hasil GDA</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-2">
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemasangan infus sesuai dengan advis dokter'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                            <label>Infus terpasang cairan</label>
                                            <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                            <label>dengan tetesan</label>
                                            <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                            <label>/menit tusukan pada</label>
                                            <input v-model="data[k]['aucap_evaluasi_t3']" class="form-control form-control-sm mx-1">
                                            <label>dengan abocath</label>
                                            <input v-model="data[k]['aucap_evaluasi_t4']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melaksanakan pemberian injeksi'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Injeksi masuk lewat</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>tanda-tanda alergi</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>keluhan</label>
                                        <input v-model="data[k]['aucap_evaluasi_t3']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pemasangan kateter tetap'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Kateter terpasang nomor</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>dengan balon WFI</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>urin keluar</label>
                                        <input v-model="data[k]['aucap_evaluasi_t3']" class="form-control form-control-sm mx-1">
                                        <label>CC setelah pemasangan</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan suction'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Lendir terhisap, suara nafas</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan penjahitan luka'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Luka terjahit</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>. Simpul dengan jenis dan ukuran benang</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                   
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan perawatan luka'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Kondisi luka setelah dilakukan perawatan</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan ekstraksi kuku'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Kuku bisa terangkat, luka bersih</span>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan incici pada...'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>sudah terambil perawatan luka telah dilakukan, luka bekas incici bersih</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang identitas warna...'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Terpasang gelang identitas warna </label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>Pasien maupun keluarga paham penjelasan petugas</label>
                                        </div>
                                    </div>
                                </div>
                                
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang gelang penanda alergi'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Terpasang gelang alergi warna merah dengan tulisan alergen </label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang bidai/spalk'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Terpasang bidai pada</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>Hasil pengecekan</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>tanda tanda kompartemen</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pengambilan benda asing/corpal'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Benda asing pada</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>pada area</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan irigasi mata'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <b-form-textarea v-model="data[k]['aucap_evaluasi_t1']" class="form-control"></b-form-textarea>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan lavement'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Lavement masuk</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>CC dengan cairan yang digunakan</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>hasil evaluasi feses</label>
                                        <input v-model="data[k]['aucap_evaluasi_t3']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan pelepasan kateter tetap'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Kateter berhasil dilakukan pelepasan dengan bleder training sebelumnya</span>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat... dengan nebulizer'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Nebulizer telah dilakukan, sesak nafas</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']"  class="form-control form-control-sm mx-1">
                                        <label>Spo2</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']"  class="form-control form-control-sm mx-1">
                                        <label>sputum</label>
                                        <input v-model="data[k]['aucap_evaluasi_t3']"  class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>


                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memasang NGT'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>NGT terpasang dengan ukuran</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>pada hidung sebelah</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>hasil evaluasi terdengar bunyi blup pada area lambung</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan perawatan luka combutio'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <div class="form-inline">
                                        <label>Luka tampak</label>
                                        <input v-model="data[k]['aucap_evaluasi_t1']" class="form-control form-control-sm mx-1">
                                        <label>Bula</label>
                                        <input v-model="data[k]['aucap_evaluasi_t2']" class="form-control form-control-sm mx-1">
                                        <label>perawatan menggunakan topical terapi</label>
                                        <input v-model="data[k]['aucap_evaluasi_t3']" class="form-control form-control-sm mx-1">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memberikan obat melalui titrasi syring pump dengan dosis'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Obat masuk melalui syring pump, lancar</span>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Melakukan CTG'">
                                    <label for="">Evaluasi</label>
                                    <div class="mb-2 evalContent">
                                        <span>Hasil terlampir</span>
                                    </div>
                                </div>
                                
                                <div class="mt-2" v-else-if="data[k]['aucap_assesmen_tindakan'] == 'Memfasilitasi pasien untuk pemeriksaan radiologi'">
                                    <div class="mb-2 evalContent">
                                        <span>Hasil terlampir</span>
                                    </div>
                                </div>




                                
                                
                            </template>
                            </td>
                            <td>
                                <div class="" style="width:100px">
                                <v-select 
                                    :options="Config.mr.isPerawatOrDokter"
                                    v-model="data[k]['aucap_ppa']"
                                    placeholder="Pilih PPA"
                                    label="text"
                                    :clearable="true" :reduce="v=>v.value"
                                >
                                </v-select>
                                </div>
                                <template>
                                    <b-form-input class="mt-2" v-model="data[k]['aucap_ppa_lainnya']" placeholder="Tulis Nama" />
                                </template>
                                <VValidate :name="'PPA #'+(k+1)" v-model="data[k]['aucap_ppa']"
                                :rules="mrValidation.aucap_ppa" />

                                
                            </td>
                            <td>
                            <div class="mb-2">
                                <b-form-checkbox @input="changeDoneInput($event,k)" :unchecked-value="'N'" :value="'Y'"
                                v-model="data[k]['aucap_is_done']" class="form-check-input-styled" name="radio-inline-left">
                                </b-form-checkbox>
                                <span v-if="data[k]['aucap_is_done'] == 'Y'">Selesai pada {{ v.aucap_evaluasi_date | moment("DD MMMM YYYY") }} {{v.aucap_evaluasi_jam}}</span>
                            </div>
                            </td>
                            <td>
                                <a href="javascript:;" class="list-icons-item"
                                   @click="removeData(v,k)"
                                    data-toggle="tooltip" data-placement="top" title="Delete"><i
                                class="icon-bin"></i></a>
                            </td>
                        </tr>

                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="6" class="text-center">
                            <a href="javascript:;" @click="addCatatan()" class="btn btn-outline-info"><i class="icon-plus2 mr-2 align-middle"></i><span class="align-middle">Tambah Catatan</span></a>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    </div>
                    <div class="table-responsive">
                    <table class="table table-bordered table-sm table-align-top">
                        <thead>
                        <tr>
                            <td colspan="6" class="bg-indigo">
                            <span class="font-weight-semibold">Catatan Asuhan Dokter</span>
                            </td>
                        </tr>
                        <tr>
                            <th>Waktu</th>
                            <th>Asesmen/Tindakan</th>
                            <th>Waktu Evaluasi</th>
                            <th>Evaluasi</th>
                            <th>PPA</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="rowDataDokter.aupd_laporan_dpjp == 'Y'">
                        <tr class="dpjpEl">
                            <td>
                                <span>{{rowDataDokter.aupd_laporan_dpjp_tanggal|moment("DD MMMM YYYY")}} 
                                {{rowDataDokter.aupd_laporan_dpjp_waktu||"-"}}</span>
                            </td>
                            
                            <td>
                                <div class="tb-content">
                                <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                                <p><span class="font-weight-semibold">S</span>: Pasien datang ke UGD dengan keluhan utama {{rowDataDokter.ausd_keluhan||"-"}} <span v-if="rowDataDokter.ausd_riwayat">, Pasien mempunyai riwayat penyakit dahulu: {{rowDataDokter.ausd_riwayat||"-"}} </span></p>
                                <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                                    <ul class="mb-0" v-if="rowDataDokter.ausd_has_alergi == 'Y'">
                                        <li v-for="(v,k) in (rowDataDokter.ausd_alergi||[])" :key="k"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v.jenis)}})</span>{{v.name||"-"}}</li>
                                    </ul>
                                    <span v-else> - </span>
                                </p>
                                <!--
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Keluhan Saat ini</span>
                                    <div>{{rowDataDokter.ausd_catatan_dokter||"-"}}</div>
                                </div>
                                -->
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                                    <div>{{rowDataDokter.aupd_rencana_pengobatan||"-"}}</div>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Riwayat Pembedahan</span>
                                    <div>
                                    <span>{{rowDataDokter.aupd_laporan_dpjp_riwayat_pembedahan||"-"}}</span>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Tindakan Invasif</span>
                                    <template v-if="(rowDataDokter.aupd_laporan_dpjp_tindakan_invasif||[]).length">
                                    <ol class="mb-0"
                                    v-for="(v,k) in (rowDataDokter.aupd_laporan_dpjp_tindakan_invasif||[])" :key="k">
                                        <li>{{v}}</li>
                                    </ol>
                                    </template>
                                    <p v-else>Tidak ada Tindakan</p>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">A: </span>Keluhan saat ini
                                    <div><span>{{rowDataDokter.aupd_laporan_dpjp_keluhan||"-"}}</span></div>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                                    <div>{{rowDataDokter.auod_skala_nyeri_value}} 
                                        <template v-if="(row.ap_usia > 6 || (row.ap_usia == null && row.ap_dob == null && rowDataDokter.auod_nyeri_type == 'NP'))">
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="rowDataDokter.auod_skala_nyeri_value == 0">Tidak Ada Nyeri</span>
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="rowDataDokter.auod_skala_nyeri_value >= 1 && rowDataDokter.auod_skala_nyeri_value <= 3">Sedikit Nyeri</span>
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="rowDataDokter.auod_skala_nyeri_value >= 4 && rowDataDokter.auod_skala_nyeri_value <= 5">Agak Mengganggu</span>
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="rowDataDokter.auod_skala_nyeri_value >= 6 && rowDataDokter.auod_skala_nyeri_value <= 7">Mengganggu Aktivitas</span>
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="rowDataDokter.auod_skala_nyeri_value >= 8 && rowDataDokter.auod_skala_nyeri_value <= 9">Sangat Mengganggu</span>
                                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="rowDataDokter.auod_skala_nyeri_value >= 10">Tak Tertahankan</span>
                                        </template>
                                        
                                        <template v-if="(row.ap_usia <= 6) || (row.ap_usia == null && row.ap_dob == null)">
                                            <template v-if="(row.ap_usia > 1 || (row.ap_usia == null && row.ap_dob == null && rowDataDokter.auod_nyeri_type == 'FL'))">
                                                <span v-if="rowDataDokter.auod_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                                <span v-else-if="rowDataDokter.auod_skala_nyeri_value >= 1 && rowDataDokter.auod_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                                <span v-else-if="rowDataDokter.auod_skala_nyeri_value >= 4 && rowDataDokter.auod_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                            </template>

                                            <template v-if="((row.ap_usia <= 1  && row.ap_usia != null)|| (row.ap_usia == null && row.ap_dob == null && rowDataDokter.auod_nyeri_type == 'NIPS')) ">
                                                <span v-if="rowDataDokter.auod_skala_nyeri_value < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                                <span v-else-if="rowDataDokter.auod_skala_nyeri_value >= 3 && rowDataDokter.auod_skala_nyeri_value < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Tanda-tanda Vital</span>
                                    <ul class="mb-0">
                                    
                                    <li>Tekanan Darah: {{rowDataDokter.auod_ttv_tekanan_darah_min||"-"}}/{{rowDataDokter.auod_ttv_tekanan_darah_max||"-"}} mmHG</li>
                                    <li>Nadi: {{rowDataDokter.auod_ttv_nadi||"-"}} x/mnt - {{rowDataDokter.auod_ttv_label||"-"}}</li>
                                    <li>Gula Darah: {{rowDataDokter.auod_ttv_gula_darah||"-"}} mg/dL</li>
                                    <li>Pernafasan: {{rowDataDokter.auod_ttv_pernafasan||"-"}} x/mnt</li>
                                    <li>SPO2: {{rowDataDokter.auod_ttv_spo2||"-"}}%</li>
                                    <li>Suhu: {{rowDataDokter.auod_ttv_suhu||"-"}}C</li>
                                    <li>Berat Badan {{rowDataDokter.auod_ttv_weight||"-"}} kg</li>
                                    <li>Tinggi: {{rowDataDokter.auod_ttv_height||"-"}} cm</li>
                                    <li>Lingkar Kepala: {{rowDataDokter.auod_ttv_lingkar_kepala||"-"}} cm</li>
                                    <li>Luas Permukaan Tubuh Anak: {{rowDataDokter.auod_ttv_luas_permukaan_anak||"-"}} M<sup>2</sup></li>
                                    <li>Kesadaran:  <span>{{rowDataDokter.auod_gcs_total}} | 
                                
                                        <span v-if="rowDataDokter.auod_gcs_total >= 14"> Composmentis</span> 
                                        <span v-else-if="rowDataDokter.auod_gcs_total >= 12 && rowDataDokter.auod_gcs_total <= 13">Apatis</span>
                                        <span v-else-if="rowDataDokter.auod_gcs_total >= 10 && rowDataDokter.auod_gcs_total <= 11">Delirium</span>
                                        <span v-else-if="rowDataDokter.auod_gcs_total >= 7 && rowDataDokter.auod_gcs_total <= 9">Somonolen</span>
                                        <span v-else-if="rowDataDokter.auod_gcs_total >= 5 && rowDataDokter.auod_gcs_total <= 6">Sopor</span>
                                        <span v-else-if="rowDataDokter.auod_gcs_total >= 4 && rowDataDokter.auod_gcs_total <= 4">Semi-Comma</span>
                                        <span v-else>Coma</span>
                                        
                                        <span class="ml-2" style="margin-right:20px;">E: 
                                            {{rowDataDokter.auod_response_mata||0}} 
                                        </span>
                                        <span style="margin-right:20px;">M: 
                                            {{rowDataDokter.auod_response_motorik||0}}  
                                        </span>
                                        <span style="margin-right:20px;">V: 
                                            {{rowDataDokter.auod_response_verbal||0}}
                                        </span>
                                    </span></li>

                                    </ul>
                                </div>
                                
                                <div class="mb-2">
                                <span class="font-weight-semibold">Pemeriksaan Fisik</span>
                                <ul class="mb-0">
                                    <template v-for="(v,k) in (Config.mr.configVitalSignUGD||[])">
                                        <div :key="k" v-if="v.value != 'auod_vs_saraf'">
                                            <li v-if="rowDataDokter[v.value] != 'TP'"> 
                                                {{v.label}} : <span>{{rowDataDokter[v.value+'_text']}}</span>
                                            </li>
                                        </div>
                                        <div :key="k" v-else>
                                            <template v-if="rowDataDokter[v.value] != 'TP'">
                                                <li>
                                                    <span> {{v.label}} :</span>
                                                </li>
                                                <p class="mb-0">Refleks Fisiologis : {{rowDataDokter.auod_vs_saraf_r_fisiologis||"-"}}</p>
                                                <p class="mb-0">Refleks Patologis : {{rowDataDokter.auod_vs_saraf_r_patologis||"-"}}</p>
                                                <p class="mb-0">Babinski : {{rowDataDokter.auod_vs_saraf_babinski||"-"}}</p>
                                                <p class="mb-0">Lasegue : {{rowDataDokter.auod_vs_saraf_lasegue||"-"}}</p>
                                                <p class="mb-0">Bregard : {{rowDataDokter.auod_vs_saraf_bregard||"-"}}</p>
                                                <p class="mb-0">Sicard : {{rowDataDokter.auod_vs_saraf_sicard||"-"}}</p>
                                                <p class="mb-0">Kaku Duduk : {{rowDataDokter.auod_vs_saraf_kaku_duduk||"-"}}</p>
                                                <p class="mb-0">Kernig Sign : {{rowDataDokter.auod_vs_saraf_kernig_sign||"-"}}</p>
                                                <p class="mb-0">Brudzinski 1: {{rowDataDokter.auod_vs_saraf_brudzinski||"-"}}</p>
                                                <p class="mb-0">Brudzinski 2: {{rowDataDokter.auod_vs_saraf_brudzinski2||"-"}}</p>
                                            </template>
                                        </div>
                                    </template>

                                </ul>
                                <span v-if="!isHavePemeriksaanFisik">Hasil pemeriksaan fisik normal</span>
                                </div>

                                <div class="mb-2">
                                    <span class="font-weight-semibold">Tindakan Lab</span>
                                    <p>{{selectedLabInput||"-"}}</p>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Tindakan Radiologi</span>
                                    <p>{{selectedRadioInput||"-"}}</p>                                    
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">R</span>
                                    <div class="mb-1">
                                    <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                                    <template v-if="(rowDataDokter.aupd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                                    <ol class="mb-0" v-for="(v,k) in (rowDataDokter.aupd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k">
                                        <li>{{v}}</li>
                                    </ol>
                                    </template>
                                    <span v-else>Tidak ada Tindakan</span>
                                    

                                    </div>
                                    <div class="mb-1">
                                    <span>Usulan kolaborasi</span>
                                    <template v-if="(rowDataDokter.aupd_laporan_dpjp_usulan_kolaborasi||[]).length">
                                    <ol class="mb-0" v-for="(v,k) in (rowDataDokter.aupd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">
                                        <li>{{v}}</li>
                                    </ol>
                                    </template>
                                    <span v-else>Tidak ada Tindakan</span>

                                    </div>
                                </div>
                                </div>
                            </td>
                            
                            <td>
                                <span>{{rowDataDokter.aupd_laporan_dpjp_tanggal_evaluasi}} {{rowDataDokter.aupd_laporan_dpjp_waktu_evaluasi}}</span>
                            </td>
                            
                            <td>
                                <div class="form-group" 
                            v-for="(vU,kU) in (rowDataDokter.aupd_laporan_dpjp_evaluasi_data||[])" :key="kU">
                                    <label for=""><b>Evaluasi #{{kU+1}}</b></label>
                                    <pre class="pre-input mb-1">{{rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['value']||"-"}}</pre>
                                    <span v-if="rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['is_done'] == 'Y'">
                                    Selesai pada {{rowDataDokter.aupd_laporan_dpjp_evaluasi_data[kU]['done_date']}}</span>

                                </div>

                                <div id="verifikasiRanap">
                                    <p class="font-weight-semibold text-blue-800">Data sudah diverifikasi oleh:</p>
                                    
                                    <div class="form-group">
                                    <label for="">Nama DPJP</label>
                                        <p>{{rowDataDokter.nama_dokter_ranap||"-"}}
                                        </p>
                                    </div>

                                    <div class="form-group">
                                    <label>Tanggal</label>
                                        <p>{{rowDataDokter.aupd_laporan_dpjp_evaluasi_date||"-"}}</p>
                                    </div>
                                    
                                    <div class="form-group">
                                    <label>Jam</label>
                                        <p>{{rowDataDokter.aupd_laporan_dpjp_evaluasi_waktu||"-"}}</p>
                                    </div>
                                </div>
                            </td>
                            
                            <td>
                                <span>{{rowDataDokter.aupd_laporan_dpjp_ppa||"-"}}</span>
                            </td>
                        </tr>
                        <tr class="dpjpEl" v-for="(v,k) in (catatanDPJPDokter||[])" :key="k">
                            <td>
                                <span>{{v.auldd_laporan_dpjp_tanggal|moment("DD MMMM YYYY")}} 
                                {{v.auldd_laporan_dpjp_waktu||"-"}}</span>
                            </td>
                            
                            <td>
                                <div class="tb-content">
                                <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                                <p><span class="font-weight-semibold">S</span>: Pasien datang ke UGD dengan keluhan utama {{v.auldd_subjektif||"-"}} <span v-if="v.auldd_riwayat_penyakit">, Pasien mempunyai riwayat penyakit dahulu: {{v.auldd_riwayat_penyakit||"-"}} </span></p>
                                <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                                    <ul class="mb-0">
                                        <li v-for="(v1,k1) in (v.auldd_riwayat_alergi||[])" :key="k1"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v1.jenis)}})</span>{{v1.name||"-"}}</li>
                                    </ul>
                                    <span v-if="!(v.auldd_riwayat_alergi||[]).length"> - </span>
                                </p>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Keluhan Saat Ini</span>
                                    <div>{{v.auldd_subjektif||"-"}}</div>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                                    <div>{{v.auldd_catatan_dokter||"-"}}</div>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Riwayat Pembedahan</span>
                                    <div>
                                    <span>{{v.auldd_laporan_dpjp_riwayat_pembedahan||"-"}}</span>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Tindakan Invasif</span>
                                    <template v-if="(v.auldd_laporan_dpjp_tindakan_invasif||[]).length">
                                    <ol class="mb-0"
                                    v-for="(v,k) in (v.auldd_laporan_dpjp_tindakan_invasif||[])" :key="k">
                                        <li>{{v}}</li>
                                    </ol>
                                    </template>
                                    <p v-else>Tidak ada Tindakan</p>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">A: </span>Keluhan saat ini
                                    <div><span>{{v.auldd_laporan_dpjp_keluhan||"-"}}</span></div>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                                    
                                    <div>{{v.auldd_hasil_skoring_nyeri}}
                                    <template v-if="(row.ap_usia > 6 || (row.ap_usia == null && row.ap_dob == null && rowDataDokter.auod_nyeri_type == 'NP'))">
                                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="v.auldd_hasil_skoring_nyeri == 0">Tidak Ada Nyeri</span>
                                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 1 && v.auldd_hasil_skoring_nyeri <= 3">Sedikit Nyeri</span>
                                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 4 && v.auldd_hasil_skoring_nyeri <= 5">Agak Mengganggu</span>
                                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 6 && v.auldd_hasil_skoring_nyeri <= 7">Mengganggu Aktivitas</span>
                                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 8 && v.auldd_hasil_skoring_nyeri <= 9">Sangat Mengganggu</span>
                                        <span class="border-left ml-2 pl-2 font-weight-semibold" v-else-if="v.auldd_hasil_skoring_nyeri >= 10">Tak Tertahankan</span>
                                    </template>
                                    
                                    <template v-if="(row.ap_usia <= 6) || (row.ap_usia == null && row.ap_dob == null)">
                                        <template v-if="(row.ap_usia > 1 || (row.ap_usia == null && row.ap_dob == null && rowDataDokter.auod_nyeri_type == 'FL'))">
                                            <span v-if="v.auldd_hasil_skoring_nyeri == 0" class="border-left ml-2 pl-2 font-weight-semibold">Santai dan Nyaman</span>
                                            <span v-else-if="v.auldd_hasil_skoring_nyeri >= 1 && v.auldd_hasil_skoring_nyeri < 4" class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan ringan</span>
                                            <span v-else-if="v.auldd_hasil_skoring_nyeri >= 4 && v.auldd_hasil_skoring_nyeri < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Ketidaknyamanan/Nyeri Parah</span>
                                        </template>

                                        <template v-if="((row.ap_usia <= 1  && row.ap_usia != null)|| (row.ap_usia == null && row.ap_dob == null && rowDataDokter.auod_nyeri_type == 'NIPS')) ">
                                            <span v-if="v.auldd_hasil_skoring_nyeri < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                            <span v-else-if="v.auldd_hasil_skoring_nyeri >= 3 && v.auldd_hasil_skoring_nyeri < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                            <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                        </template>
                                    </template>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Tanda-tanda Vital</span>
                                    <ul class="mb-0">
                                    
                                    <li>Tekanan Darah: {{v.auldd_ttv_tekanan_darah_min||"-"}}/{{v.auldd_ttv_tekanan_darah_max||"-"}} mmHG</li>
                                    <li>Nadi: {{v.auldd_ttv_nadi||"-"}} x/mnt - {{v.auldd_ttv_label||"-"}}</li>
                                    <li>Gula Darah: {{v.auldd_ttv_gula_darah||"-"}} mg/dL</li>
                                    <li>Pernafasan: {{v.auldd_ttv_pernafasan||"-"}} x/mnt</li>
                                    <li>SPO2: {{v.auldd_ttv_spo2||"-"}}%</li>
                                    <li>Suhu: {{v.auldd_ttv_suhu||"-"}}C</li>
                                    <li>Berat Badan {{v.auldd_ttv_weight||"-"}} kg</li>
                                    <li>Tinggi: {{v.auldd_ttv_height||"-"}} cm</li>
                                    <li>Lingkar Kepala: {{v.auldd_ttv_lingkar_kepala||"-"}} cm</li>
                                    <li>Luas Permukaan Tubuh Anak: {{v.auldd_ttv_luas_permukaan_anak||"-"}} M<sup>2</sup></li>
                                    <li>Kesadaran: <span>{{v.auldd_hasil_kesadaran}} | 
                                
                                        <span v-if="v.auldd_hasil_kesadaran >= 14"> Composmentis</span> 
                                        <span v-else-if="v.auldd_hasil_kesadaran >= 12 && v.auldd_hasil_kesadaran <= 13">Apatis</span>
                                        <span v-else-if="v.auldd_hasil_kesadaran >= 10 && v.auldd_hasil_kesadaran <= 11">Delirium</span>
                                        <span v-else-if="v.auldd_hasil_kesadaran >= 7 && v.auldd_hasil_kesadaran <= 9">Somonolen</span>
                                        <span v-else-if="v.auldd_hasil_kesadaran >= 5 && v.auldd_hasil_kesadaran <= 6">Sopor</span>
                                        <span v-else-if="v.auldd_hasil_kesadaran >= 4 && v.auldd_hasil_kesadaran <= 4">Semi-Comma</span>
                                        <span v-else>Coma</span>
                                               
                                        <span class="ml-2" style="margin-right:20px;">E: 
                                            {{v.auldd_hasil_kesadaran_e||0}} 
                                        </span>
                                        <span style="margin-right:20px;">M: 
                                            {{v.auldd_hasil_kesadaran_m||0}}  
                                        </span>
                                        <span style="margin-right:20px;">V: 
                                            {{v.auldd_hasil_kesadaran_v||0}}
                                        </span>
                                    </span></li>

                                    </ul>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Tindakan Lab</span>
                                    <template v-if="(v.auldd_hasil_lab||[]).length">
                                    <ol class="mb-0" v-for="(v3,k3) in (v.auldd_hasil_lab||[])" :key="k3">
                                        <li>{{v3.value}}</li>
                                    </ol>
                                    </template>
                                    <p v-else> - </p>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">Tindakan Radiologi</span>
                                    <template v-if="(v.auldd_hasil_radiologi||[]).length">
                                    <ol class="mb-0" v-for="(v4,k4) in (v.auldd_hasil_radiologi||[])" :key="k4">
                                        <li>{{v4.value}}</li>
                                    </ol>
                                    </template>
                                    <p v-else> - </p>
                                </div>
                                <div class="mb-2">
                                    <span class="font-weight-semibold">R</span>
                                    <div class="mb-1">
                                    <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                                    <template v-if="(v.auldd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                                    <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k">
                                        <li>{{v}}</li>
                                    </ol>
                                    </template>
                                    <p v-else> - </p>
                                    

                                    </div>
                                    <div class="mb-1">
                                    <span>Usulan kolaborasi</span>
                                    <template v-if="(v.auldd_laporan_dpjp_usulan_kolaborasi||[]).length">
                                    <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">
                                        <li>{{v}}</li>
                                    </ol>
                                    </template>
                                    <p v-else> - </p>

                                    </div>
                                </div>
                                </div>
                            </td>
                            
                            <td>
                                <span>{{v.auldd_laporan_dpjp_tanggal_evaluasi}} {{v.auldd_laporan_dpjp_waktu_evaluasi}}</span>
                            </td>
                            
                            <td>
                                <div class="form-group" 
                                v-for="(vU,kU) in (v.auldd_laporan_dpjp_evaluasi_data||[])" :key="kU">
                                    <label for=""><b>Evaluasi #{{kU+1}}</b></label>
                                    <pre class="pre-input mb-1">{{ v.auldd_laporan_dpjp_evaluasi_data[kU]['value']||'-' }}</pre>
                                        
                                    <span v-if="v.auldd_laporan_dpjp_evaluasi_data[kU]['is_done'] == 'Y'">Selesai pada {{v.auldd_laporan_dpjp_evaluasi_data[kU]['done_date']}}</span>

                                </div>
                                        
                                <div id="verifikasiRanap">
                                    <p class="font-weight-semibold text-blue-800">Data sudah diverifikasi oleh:</p>
                                    
                                    <div class="form-group">
                                    <label for="">Nama DPJP</label>
                                        <p>{{v.nama_dokter_ranap||"-"}}
                                        </p>
                                    </div>

                                    <div class="form-group">
                                    <label>Tanggal</label>
                                        <p>{{v.aupd_laporan_dpjp_evaluasi_date||"-"}}</p>
                                    </div>
                                    
                                    <div class="form-group">
                                    <label>Jam</label>
                                        <p>{{v.aupd_laporan_dpjp_evaluasi_waktu||"-"}}</p>
                                    </div>
                                </div>
                            </td>
                            
                            <td>
                                <span>{{v.auldd_laporan_dpjp_ppa||"-"}}</span>
                            </td>
                        </tr>
                        </template>
                        <tr class="table-info">
                            <td colspan="6"><span class="font-weight-semibold text-info-600">Catatan Asuhan Lainnya</span></td>
                        </tr>
                        <tr v-for="(v,k) in (dataKajianDokter||[])" :key="k">
                            <td>
                            <span>{{v.aucad_tanggal | moment("DD MMMM YYYY")}}, {{v.aucad_jam}}</span>
                            </td>
                            <td>
                            <span>{{v.aucad_assesmen||"-"}}</span>
                            </td>
                            <td>
                            <span>{{v.aucad_tanggal_evaluasi | moment("DD MMMM YYYY")}}, {{v.aucad_jam_evaluasi||"-"}}</span>
                            </td>
                            <td>                            
                            <span>{{v.aucad_evaluasi||"-"}}</span>
                            </td>
                            <td>
                            <span>{{v.aucad_ppa||"-"}}</span>
                            </td>
                            <td>
                            <span v-if="v.aucad_is_active != 'Y'"> - </span>
                            <span v-else>Selesai pada {{v.aucad_done_at | moment("DD MMMM YYYY, HH:mm")}}</span>
                            </td>
                        </tr>
                        <tr v-if="!(dataKajianDokter||[]).length">
                            <td colspan="6" class="text-center">Tidak ada data</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>

                    <div class="table-responsive">
                        <table v-if="showIntervensi || (row.ap_usia == null && row.ap_dob == null && (edTotal >= 90 || hdTotal >= 12 || morsheTotal >= 25 || getTotalSydney >=6))" class="table table-bordered table-sm table-striped">
                        <thead>
                            <tr>
                                <td colspan="2" class="bg-indigo">
                                <span class="font-weight-semibold">Intervensi Risiko Jatuh</span>
                                </td>
                            </tr>
                            <tr>
                                <th width="50%">Tindakan</th>
                                <th width="50%">Evaluasi</th>
                            </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="dataKajianPerawat.aukprj_memasang_gelang == 'Y'">
                                        <td>
                                            Memasang gelang kuning risiko jatuh
                                        </td>
                                        <td>
                                            <div class="form-inline">
                                                <label>Terpasang gelang warna kuning pada</label>
                                                <b-form-input v-model="dataKajianPerawat.aukprj_memasang_gelang_text" type="text" class="form-control form-control-sm mx-2" />
                                                <label>pasien</label>
                                            </div>

                                            <VValidate 
                                                name="Memasang Gelang Kuning" 
                                                v-if="dataKajianPerawat.aukprj_memasang_gelang == 'Y'"
                                                v-model="dataKajianPerawat.aukprj_memasang_gelang_text" 
                                                :rules="{required: 1}"
                                            />
                                        </td>
                                    </tr>
                                    <tr  v-if="dataKajianPerawat.aukprj_memasang_pengaman == 'Y'">
                                        <td>
                                            Memasang pagar pengaman tempat tidur
                                        </td>
                                        <td>
                                            <span>Pagar pengaman tempat tidur terpasang pada kedua sisi</span>
                                        </td>
                                    </tr>
                                    <tr v-if="dataKajianPerawat.aukprj_memasang_roda == 'Y'">
                                        <td>
                                            Mengunci roda tempat tidur
                                        </td>
                                        <td>
                                            <span>Roda tempat tidur terkunci dan tidak mudah tergeser</span>
                                        </td>
                                    </tr>
                                    <tr v-if="dataKajianPerawat.aukprj_memasang_restrain == 'Y'">
                                        <td>
                                        Memasang restrain
                                        </td>
                                        <td>
                                            <div class="form-inline">
                                                <label>Terpasang restrain di</label>
                                                <b-form-input v-model="dataKajianPerawat.aukprj_memasang_restrain_text" type="text" class="form-control form-control-sm mx-2" />
                                                <label>pasien, restrain menggunakan kain</label>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="dataKajianPerawat.aukprj_memasang_edukasi == 'Y'">
                                        <td>
                                            Memberikan edukasi pencegahan jatuh pada pasien dan keluarga
                                        </td>
                                        <td>
                                            <span>Edukasi tentang pasien dipastikan selalu ada yang menunggu</span>
                                        </td>
                                    </tr>
                                </tbody>
                        </table>
                    </div>

                    <div class="card-footer">
                    <div class="text-right">
                        <a href="javascript:;" @click="back()" class="btn">Kembali</a>
                        <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                    </div>
                    </div>
                </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default {
    extends: GlobalVue,
    data() {
        return {
            rowReg: {},
            data: [],
            dataKajianDokter: [],
            dataKajianPerawat: {},
            catatanDPJPDokter: [],
            rowDataDokter: {}
        }
    },
    computed:{
        now() {
            return moment()
        },
        
        isHavePemeriksaanFisik(){
            let total = 0
            for(let i = 0; i < (this.Config.mr.configVitalSignUGD||[]).length; i++){
                if(this.rowDataDokter[this.Config.mr.configVitalSignUGD[i]['value']] != 'TP'){
                    total += 1
                }
            }
            return total
        },
        
        edTotal(){
            return (this.dataKajianPerawat.aukprj_ed_umur||0) + 
            (this.dataKajianPerawat.aukprj_ed_status_mental||0) + 
            ((this.dataKajianPerawat.aukprj_ed_pola_pikir == 11 ? (11+1): this.dataKajianPerawat.aukprj_ed_pola_pikir)||0) + 
            (this.dataKajianPerawat.aukprj_ed_pengobatan||0) + 
            (this.dataKajianPerawat.aukprj_ed_diagnosis||0) + 
            (this.dataKajianPerawat.aukprj_ed_ambulasi||0) + 
            (this.dataKajianPerawat.aukprj_ed_nutrisi||0) + 
            (this.dataKajianPerawat.aukprj_ed_riwayat_jatuh||0) 
        },

        hdTotal(){
            return (this.dataKajianPerawat.aukprj_humpty_umur||0) + 
            (this.dataKajianPerawat.aukprj_humpty_jenis_kelamin||0) + 
            (this.dataKajianPerawat.aukprj_humpty_diagnosis||0) + 
            (this.dataKajianPerawat.aukprj_humpty_gangguan_kognitif||0) + 
            (this.dataKajianPerawat.aukprj_humpty_faktor_lingkungan||0) + 
            (this.dataKajianPerawat.aukprj_humpty_respon_pembedahan||0) +
            (this.dataKajianPerawat.aukprj_humpty_respon_penggunaan_obat||0) 
        },

        morsheTotal(){
            return (this.dataKajianPerawat.aukprj_morshe_riwayat_jatuh||0) + 
            (this.dataKajianPerawat.aukprj_morshe_diagnosa||0) + 
            (this.dataKajianPerawat.aukprj_morshe_alat_bantu||0) + 
            (this.dataKajianPerawat.aukprj_morshe_inpus||0) + 
            (this.dataKajianPerawat.aukprj_morshe_cara_jalan||0) + 
            (this.dataKajianPerawat.aukprj_morshe_status_mental||0) 
        },

        getTotalSydney(){
            let total = 0
            
            if(this.dataKajianPerawat.aukprj_riwayat_jatuh_is_jatuh == "Y" || this.dataKajianPerawat.aukprj_riwayat_jatuh_is_jatuh2bulan == "Y"){
                total = total + 6
            }

            if(this.dataKajianPerawat.aukprj_status_mental_dellirium == "Y" || this.dataKajianPerawat.aukprj_status_mental_disorientasi == "Y" ||
            this.dataKajianPerawat.aukprj_status_mental_agitasi == "Y" ){
                total = total + 14
            }
            
            if(this.dataKajianPerawat.aukprj_penglihatan_kacamata == "Y" || this.dataKajianPerawat.aukprj_penglihatan_buram == "Y" ||
            this.dataKajianPerawat.aukprj_penglihatan_glaucoma == "Y" ){
                total = total + 1
            }

            if(this.dataKajianPerawat.aukprj_kebiasaan == "Y"){
                total = total + 2
            }

            if(this.dataKajianPerawat.aukprj_transfer + this.dataKajianPerawat.aukprj_mobilitas > 3){
                total = total + 7
            }
            return total  
        },
        showIntervensi(){
            if(this.dataKajianPerawat.aukprj_is_gangguan_jiwa == 'Y'){
                return this.edTotal >= 90
            }
            else{
                if(this.row.ap_usia <= 18){
                    return this.hdTotal >= 12
                }else if(this.row.ap_usia > 18 && this.row.ap_usia <= 60){
                    return this.morsheTotal >= 25
                }else if(this.row.ap_usia > 60){
                    return this.getTotalSydney >=6
                }else{
                    return false
                }
            }
        },

        selectedLabInput(){
            let data = []
            for(let ik = 0; ik < (this.rowDataDokter.aupdl_data||[]).length; ik++){
                for(let jk = 0; jk < (this.rowDataDokter.aupdl_data[ik]['dubData']||[]).length; jk++){
                for(let kk = 0; kk < (this.rowDataDokter.aupdl_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
                    if(this.rowDataDokter.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']){
                        data.push(this.rowDataDokter.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
                    }
                }
                }
            }
            if(this.rowDataDokter.aupdl_hasil_lainnya){
                data.push(this.rowDataDokter.aupdl_hasil_lainnya)
            }
            return data.join(", ")
        },

        selectedRadioInput(){
            let data = []
            for(let i = 0; i < (this.rowDataDokter.aupdr_data||[]).length; i++){
                for(let j = 0; j < (this.rowDataDokter.aupdr_data[i]['data']||[]).length; j++){  
                    if(this.rowDataDokter.aupdr_data[i]['data'][j]['selected']){
                    data.push(this.rowDataDokter.aupdr_data[i]['data'][j]['text'])
                    }
                }
            }
            if(this.rowDataDokter.aupdr_hasil_lainnya){
                data.push(this.rowDataDokter.aupdr_hasil_lainnya)
            }
            return data.join(", ")
        },
    },
    components: {
        Datepicker,VueTimepicker
    },
    methods: {   
        
        inputDataCA(date,jam,k){
            if(date && jam){
                this.data[k]['aucap_is_done'] = "Y"
            }else{
                this.data[k]['aucap_is_done'] = "N"
            }
        },
        changeAsesmenTindakan(k){
            this.data[k]['aucap_evaluasi_t1'] = null
            this.data[k]['aucap_evaluasi_t2'] = null
            this.data[k]['aucap_evaluasi_t3'] = null
            this.data[k]['aucap_evaluasi_t4'] = null
            this.data[k]['aucap_evaluasi_t5'] = null
            this.data[k]['aucap_evaluasi_text'] = null
        },
        changeDoneInput(e,k){
            if(e == "Y"){
                this.data[k]['aucap_done_date'] = this.now
            }else{
                this.data[k]['aucap_done_date'] = null
            }
        },
        back(){
            this.$router.back()
        },
        apiGet(params = {}, page = 1){
            
            this.loadingOverlay = true
            
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })

                if(!this.isFound){
                    this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
            }).catch(()=>{
                this.loadingOverlay = false
            })
        },
        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },
        removeData(v,k){
            let data = {
                id: v.aucap_id,
                type: 'remove-catatan-asuhan'
            }
            Gen.apiRest(
            "/do/" + 'UGDCatatanAsuhan', {
                data: data
            },
            "POST"
            ).then(() => {
                this.data.splice(k,1)
            })
        },
        addCatatan(){
            let data = {
                aucap_aur_id : this.$route.params.pageSlug,
                aucap_type: 'L',
                aucap_date: moment().format('YYYY-MM-DD'),
                aucap_jam: moment().format('HH:mm'),
                aucap_assesmen_tindakan: null,
                aucap_assesmen_tindakan_text: null,
                aucap_evaluasi_date: moment().format('YYYY-MM-DD'),
                aucap_evaluasi_jam: '',
                
                // aucap_evaluasi_date: moment().format('YYYY-MM-DD'),
                // aucap_evaluasi_jam: moment().add(10, 'minutes').format('HH:mm'),
                aucap_evaluasi_t1: null,
                aucap_evaluasi_t2: null,
                aucap_evaluasi_t3: null,
                aucap_evaluasi_t4: null,
                aucap_evaluasi_t5: null,
                aucap_evaluasi_text: null,
                aucap_ppa: 'Perawat',
                aucap_ppa_lainnya: this.user.fullName,
                aucap_assesmen_tindakan_sup_text: null,
                aucap_assesmen_tindakan_sup_text2: null,
                aucap_is_done: 'N',
                aucap_done_date: this.now,
                aucap_created_from: 'CA',
                type: 'add-catatan-asuhan'
            }
            console.log(data)

            Gen.apiRest(
                "/do/" + 'UGDCatatanAsuhan', {
                data: data
                },
                "POST"
            ).then(res => {
                let resp = res.data
                this.data.push(resp.row)
            })
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        doSubmit(){
            this.$refs['VForm'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                if(el[i].style.display !== 'none'){
                                    inv.push(el[i].id)
                                }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        text: this.row.ar_reg_code ? "Perubahan Data pada dokter, waktu periksa, dan poli akan mengubah nomor antrean" : "",
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'
                            data.data = this.data
                            data.regID = this.rowReg.aur_id

                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'UGDCatatanAsuhan',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                }).then(result => {
                                    if (result.value) {
                                        if(this.user.levelId == 1){
                                            this.$router.push({ name: 'UGDAssesment', params: { pageSlug: this.rowReg.aur_ap_id }, query: {regId: this.rowReg.aur_id, byPassLevel: this.user.levelId == 1 ? this.uPerawat : null } }).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'UGDAssesment', params: { pageSlug: this.rowReg.aur_ap_id }, query: {regId: this.rowReg.aur_id} }).catch(()=>{})
                                        }
                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message

                                    if(err.title == "Terjadi gangguan koneksi pada server saat mengirim data"){
                                        this.$router.push({name : 'RoReservasi'}).catch(()=>{})
                                    }
                                }
                                this.doSetAlertForm(err)
                            })

                        }
                    })

                    // this.loadingOverlay = true

                }
            })
        },
        autoSaveCatatanAsuhan: _.debounce(function (data) {
            let dataPost = {
                type: 'auto-save-catatan-asuhan',
                data: data
            }
            Gen.apiRest(
                "/do/" + 'UGDCatatanAsuhan', {
                data: dataPost
                },
                "POST"
            )
        }, 1000),
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            Gen.apiRest(
                "/do/"+'UGDCatatanAsuhan',
                {data:data}, 
                "POST"
            )
        },1000),
    },
    mounted() {
        setTimeout(()=>{
            this.initSticky()
        },1000)
    
        this.apiGet()
    },

    
    watch:{
        data: {
            handler(v) {
                this.autoSaveCatatanAsuhan(v)
            },
            deep: true
        },
        dataKajianPerawat: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
    }
}
</script>